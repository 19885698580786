<template>
  <Layout>
    <BHeader>
      <el-form
        :model="collectionForm"
        inline
      >
        <el-form-item label="是否要对新用户采集标签">
          <el-switch
            v-model="collectionForm.collectionStatus"
            v-loading="switchLoading"
            :disabled="switchLoading"
            :active-value="1"
            :inactive-value="2"
            active-text="是"
            inactive-text="否"
            @change="handleUpdateCollection"
          />
        </el-form-item>
      </el-form>
      <template #right>
        <el-button
          type="primary"
          @click="handleAddNewTag"
        >
          新增标签
        </el-button>
      </template>
    </BHeader>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      border
      style="width: 100%"
    >
      <el-table-column
        prop="chTagName"
        label="标签"
        min-width="100"
      />
      <el-table-column
        prop="tag"
        label="图片"
        min-width="100"
      >
        <template #default="{row}">
          <el-image
            class="game-cover"
            fit="contain"
            :src="row.image"
            lazy
          />
        </template>
      </el-table-column>
      <el-table-column
        label="多语言"
        align="center"
      >
        <el-table-column
          v-for="lang in langList"
          :key="lang"
          :label="(langMap[lang] || {}).name || '未定义'"
          :prop="`tagName.${lang}`"
          align="center"
        />
      </el-table-column>
      <el-table-column
        label="操作"
        width="140px"
      >
        <template #default="{row}">
          <el-button
            type="text"
            @click="handleEditTag(row)"
          >
            编辑
          </el-button>
          <el-button
            type="text"
            @click="handleDeleteTag(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryData"
    />
    <el-drawer
      :title="isEditOpen ? '编辑标签' : '增加标签'"
      :visible.sync="drawerVisible"
      direction="rtl"
      size="500px"
    >
      <el-form
        ref="subForm"
        :model="subFormData"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item
          prop="chTagName"
          label="标签"
          inline
        >
          <el-input
            v-model.trim="subFormData.chTagName"
            placeholder="标签中文名称"
          />
        </el-form-item>
        <el-form-item
          prop="image"
          label="图片"
        >
          <UploadImage
            :limit="1"
            :file-list.sync="imageList"
          />
        </el-form-item>
        <el-form-item
          v-for="lang in langList"
          :key="lang"
          :prop="`tagName.${lang}`"
          :label="`多语言-${(langMap[lang] || {}).name || '未定义'}`"
          :rules="[
            {required: true, message: '多语言必填'}
          ]"
        >
          <el-input
            v-model.trim="subFormData.tagName[lang]"
            placeholder="标签名称-多语言"
          />
        </el-form-item>
      </el-form>
      <div style="text-align:right;">
        <el-button
          @click="drawerVisible=false"
        >
          取消
        </el-button>
        <el-button
          type="primary"
          :loading="editLoading"
          @click="handleConfirm"
        >
          确认
        </el-button>
      </div>
    </el-drawer>
  </Layout>
</template>

<script>
import {
  langMap
} from '@/utils/selectOptions.js'
import {
  addUserTag,
  updateUserTag,
  getUserTagList,
  deleteUserTag,
  getTagCollection,
  setupTagCollection
} from '@/api/tag-manage.js'
import { cloneDeep } from 'lodash'
import { mapState } from 'vuex'

export default {
  name: 'TagManage',
  data () {
    const imageListValidator = (rule, value, callback) => {
      if (this.imageList.length) {
        return callback()
      } else {
        callback(new Error('图片必填'))
      }
    }

    return {
      switchLoading: false,
      collectionForm: {
        collectionStatus: ''
      },
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      langList: ['en', 'th'],
      langMap,
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      subFormData: {
        tagName: {}
      },
      rules: {
        chTagName: [{ required: true, message: '标签必填' }],
        image: [{ required: true, validator: imageListValidator }]
      },
      drawerVisible: false,
      editLoading: false,
      imageList: [],
      isEditOpen: false
    }
  },
  computed: {
    ...mapState('app', ['appId', 'platform'])
  },
  created () {
    this.handleSearch()
    this.getTagCollection()
  },
  methods: {
    getTagCollection () {
      getTagCollection({
        appId: this.appId,
        platform: this.platform
      }).then(res => {
        if (res.code === 200) {
          this.collectionForm.collectionStatus = res.data.status
        }
      })
    },
    handleUpdateCollection () {
      if (this.switchLoading) return
      this.switchLoading = true
      const { collectionStatus: status } = this.collectionForm
      setupTagCollection({
        status,
        appId: this.appId,
        platform: this.platform
      })
        .then(res => {
          if (res.code === 200) {
            this.$message.success('更新成功')
          }
        })
        .finally(() => {
          this.switchLoading = false
        })
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryData()
    },
    queryData () {
      this.listInfo.loading = true
      getUserTagList({
        ...this.formData,
        appId: this.appId,
        platform: this.platform
      })
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    initSubFormData () {
      this.imageList = []
      this.subFormData = {
        tagName: {}
      }
    },
    handleAddNewTag () {
      this.initSubFormData()
      this.isEditOpen = false
      setTimeout(() => {
        this.drawerVisible = true
      })
    },
    handleEditTag (row) {
      this.isEditOpen = true
      this.subFormData = row
      this.imageList = [{ url: row.image }]
      setTimeout(() => {
        this.drawerVisible = true
      })
    },
    handleConfirm () {
      this.$refs.subForm.validate((valid) => {
        if (valid) {
          const formData = cloneDeep(this.subFormData)
          formData.image = this.imageList[0].url

          this.editLoading = true
          if (this.isEditOpen) {
            updateUserTag({
              ...formData,
              appId: this.appId,
              platform: this.platform
            })
              .then(res => {
                if (res.code === 200) {
                  this.$message.success('已更新')
                  this.queryData()
                  this.drawerVisible = false
                }
              })
              .finally(() => {
                this.editLoading = false
              })
          } else {
            addUserTag({
              ...formData,
              appId: this.appId,
              platform: this.platform
            })
              .then(res => {
                if (res.code === 200) {
                  this.$message.success('新增成功')
                  this.queryData()
                  this.drawerVisible = false
                }
              })
              .finally(() => {
                this.editLoading = false
              })
          }
        }
      })
    },
    handleDeleteTag (row) {
      this.$confirm(`此操作将删除标签${row.chTagName}, 是否继续`)
        .then(() => {
          deleteUserTag({ id: row.id })
            .then(res => {
              if (res.code === 200) {
                this.queryData()
                this.$message.success('删除成功')
              }
            })
        })
    }
  }
}
</script>

<style scoped lang="less">
  .game-cover{
    width: 96px;
    height: 50px;
    border-radius: 4px;
  }
</style>
