import request from '@/utils/request'

// 新建用户标签
export function addUserTag (data) {
  return request({
    url: '/user/addUserTag',
    data
  })
}

// 新建用户标签
export function updateUserTag (data) {
  return request({
    url: '/user/updateUserTag',
    data
  })
}

// 获取用户标签列表
export function getUserTagList (data) {
  return request({
    url: '/user/getUserTagList',
    data
  })
}

// 获取用户标签列表
export function deleteUserTag (data) {
  return request({
    url: '/user/deleteUserTag',
    data
  })
}

// 获取新用户采集标签开关
export function getTagCollection (data) {
  return request({
    url: '/user/getTagCollection',
    data
  })
}

// 新用户采集标签开关
export function setupTagCollection (data) {
  return request({
    url: '/user/setupTagCollection',
    data
  })
}
